import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { info } from "../../info";
import Button from "../Button";
import Hero from "../Hero";
import IconBox from "../IconBox";
import Modal from "../Modal";
import Section from "../Section";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Team from "../Team";

export default function Home() {
  const [homepagecontent, sethomepagecontent] = useState(null);
  const [bets, setbets] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);

    const get = async () => {
      const items = await axios.get(info.backend_url + "items/homepage");
      sethomepagecontent(items.data.data);

      const initialUrl = `${info.backend_url}items/bets?limit=3&page=1&sort[]=-match_time&filter[status][_eq]=1`;
      //draft 0 published 1 archived 2
      const items1 = await axios.get(initialUrl);
      setbets(items1.data.data);
    };

    get();
  }, []);

  return (
    <>
      <Hero
        title={homepagecontent ? homepagecontent.heading : ""}
        mintDeathLine="22 July"
        bgUrl={
          homepagecontent &&
          info.backend_url + "assets/" + homepagecontent.background
        }
        heroImageUrl={
          homepagecontent &&
          info.backend_url + "assets/" + homepagecontent.banner
        }
        animatedUrl={
          homepagecontent &&
          info.backend_url + "assets/" + homepagecontent.animated_banner
        }
        buttonName={homepagecontent && homepagecontent.button1_name}
        buttonLink={homepagecontent && homepagecontent.button1_link}
        overlay="0.3"
        bgOpacity="0.4"
      />

      <Section tag="section" id="about">
        <Spacing lg="100" md="70" />
        <Section className="container">
          <Section className="row align-content-center">
            <Section className="col-lg-6">
              <Section className="cs-right_space_40">
                <SectionHeading
                  title={homepagecontent && homepagecontent.about_big_title}
                  subtitle={
                    homepagecontent && homepagecontent.aboout_small_title
                  }
                />
                <Spacing lg="20" md="20" />
                <Section tag="p">
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        homepagecontent && homepagecontent.about_description,
                    }}
                  />
                </Section>

                <Spacing lg="25" md="15" />
              </Section>
              <Spacing lg="0" md="40" />
            </Section>
            <Section className="col-lg-6">
              <img
                src={
                  homepagecontent &&
                  info.backend_url + "assets/" + homepagecontent.about_image
                }
                alt="About"
              />
            </Section>
          </Section>
        </Section>
        <Spacing lg="100" md="70" />
      </Section>
      <Section className="container">
        <Section className="row">
          <Section className="col-lg-4">
            <IconBox
              title={homepagecontent && homepagecontent.first_title}
              subtitle={homepagecontent && homepagecontent.first_content}
            >
              01
            </IconBox>
            <Spacing lg="25" md="25" />
          </Section>
          <Section className="col-lg-4">
            <IconBox
              title={homepagecontent && homepagecontent.second_title}
              subtitle={homepagecontent && homepagecontent.second_content}
            >
              02
            </IconBox>
            <Spacing lg="25" md="25" />
          </Section>
          <Section className="col-lg-4">
            <IconBox
              iconNumber="02"
              title={homepagecontent && homepagecontent.third_title}
              subtitle={homepagecontent && homepagecontent.third_content}
            >
              03
            </IconBox>
            <Spacing lg="25" md="25" />
          </Section>
        </Section>
      </Section>
      <Spacing lg="70" md="45" />

      <Section id="team">
        <Section className="container">
          <SectionHeading
            title={homepagecontent && homepagecontent.bets_big_title}
            subtitle={homepagecontent && homepagecontent.bets_small_title}
          />
          <Spacing lg="50" md="30" />
          <Section className="row">
            {bets && bets.length > 0 ? (
              bets.map((e) => (
                <Section className="col-lg-4 col-sm-6" key={e.id}>
                  <Link to={`/bet/${e.id}`}>
                    <Team
                      firstTeamImage={
                        info.backend_url + "assets/" + e.firstteamlogo
                      }
                      secondTeamImage={
                        info.backend_url + "assets/" + e.secondteamlogo
                      }
                      firstTeamName={e.first_team}
                      secondTeamName={e.second_team}
                      facebookHref={`/bet/${e.id}`}
                    />
                    <Spacing lg="25" md="25" />
                  </Link>
                </Section>
              ))
            ) : (
              <div className="row">
                <div className="col-12">
                  <h3 className="text-center">No result</h3>
                </div>
              </div>
            )}
          </Section>
        </Section>
      </Section>
    </>
  );
}
