import React from "react";
import Section from "../Section";
import { Icon } from "@iconify/react";

export default function SectionHeading({
  title,
  subtitle,
  showFilters,
  setShowFilters,
}) {
  return (
    <Section className="cs-seciton_heading cs-style1">
      <Section
        tag="h3"
        className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color text-uppercase"
      >
        {subtitle}
      </Section>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Section
          tag="h2"
          className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm"
        >
          {title}
        </Section>
        {setShowFilters && (
          <Section
            tag="span"
            className="cs-btn cs-btn_filed cs-accent_btn"
            onClick={() => setShowFilters((e) => !e)}
          >
            <Icon
              icon={showFilters ? "ion:close-outline" : "ion:filter-outline"}
            />
            <Section tag="span">
              {showFilters ? "Hide Filters" : "Show Filters"}
            </Section>
          </Section>
        )}
      </div>
    </Section>
  );
}
