import { Icon } from "@iconify/react";
import axios from "axios";
import { ConnectKitButton } from "connectkit";
import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { info } from "../../info";
import Section from "../Section";
import "./header.scss";
import DropDown from "./DropDown";
import { useAccount } from "wagmi";

export default function Header() {
  const [mobileToggle, setMobileToggle] = useState(false);
  const [siteInfo, setSiteInfo] = useState(null);
  const { address, isConnected } = useAccount();

  useEffect(() => {
    const get = async () => {
      const items = await axios.get(
        info.backend_url + "items/website_settings"
      );
      setSiteInfo(items.data.data);
    };
    get();
  }, []);

  function truncateEthereumAddress(address) {
    const truncatedAddress =
      address.substring(0, 6) + "..." + address.substring(address.length - 4);
    return truncatedAddress;
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={siteInfo && siteInfo.website_seo_description}
        />
        <title>{siteInfo && siteInfo.website_title}</title>
      </Helmet>
      <Section
        tag="header"
        className="cs-site_header cs-style1 cs-sticky-header cs-primary_color"
      >
        <Section className="cs-main_header">
          <Section className="container">
            <Section className="cs-main_header_in">
              <Section className="cs-main_header_left">
                <Link to="/" className="cs-site_branding cs-accent_color">
                  <img
                    src={
                      siteInfo && info.backend_url + "assets/" + siteInfo.logo
                    }
                    alt="Logo"
                    className="cs-hide_dark"
                  />
                  <img
                    src={
                      siteInfo && info.backend_url + "assets/" + siteInfo.logo
                    }
                    alt="Logo"
                    className="cs-hide_white"
                  />
                </Link>
              </Section>
              <Section className="cs-main_header_center">
                <Section className="cs-nav">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? "block" : "none"}` }}
                  >
                    <li>
                      <Link to="/" onClick={() => setMobileToggle(false)}>
                        Home
                      </Link>
                      {/* <DropDown>
                        <ul>
                          <li>
                            <Link to="/">Default Home</Link>
                          </li>
                          <li>
                            <Link to="/home2">Home Version-2</Link>
                          </li>
                          <li>
                            <Link to="/home3">Home Version-3</Link>
                          </li>
                        </ul>
                      </DropDown> */}
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="#" onClick={() => setMobileToggle(false)}>
                        Bets
                      </Link>
                      <DropDown>
                        <ul>
                          <li>
                            <Link to="/bets">Active Bets</Link>
                          </li>
                          <li>
                            <Link to="/bets-archived">Archived Bets</Link>
                          </li>
                        </ul>
                      </DropDown>
                    </li>

                    <li>
                      <Link to="contact" onClick={() => setMobileToggle(false)}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? "cs-munu_toggle cs-toggle_active"
                        : "cs-munu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Section>
              </Section>
              <Section className="cs-main_header_right">
                <Section className="cs-toolbox">
                  <ConnectKitButton.Custom>
                    {({ show, hide, ensName, chain }) => {
                      return (
                        <Section
                          tag="span"
                          className="cs-btn cs-btn_filed cs-accent_btn"
                          onClick={show}
                        >
                          <Icon icon="ion:wallet-outline" />
                          <Section tag="span">
                            {address
                              ? truncateEthereumAddress(address)
                              : "Connect"}
                          </Section>
                        </Section>
                      );
                    }}
                  </ConnectKitButton.Custom>
                </Section>
              </Section>
            </Section>
          </Section>
        </Section>
      </Section>
    </>
  );
}
