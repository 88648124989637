import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import Section from "../Section";
import "./team.scss";

export default function Team({
  firstTeamImage,
  secondTeamImage,
  firstTeamName,
  secondTeamName,
  facebookHref,
}) {
  return (
    <Section className={`cs-team cs-style1 text-center cs-light_bg`}>
      <div className="row">
        <div className="col-5">
          <img
            src={firstTeamImage}
            alt="Member"
            className="img-fluid"
            style={{ width: "150px", height: "150px" }}
          />
        </div>
        <div className="col-2"></div>
        <div className="col-5">
          <img
            src={secondTeamImage}
            alt="Member"
            className="img-fluid"
            style={{ width: "150px", height: "150px" }}
          />
        </div>
      </div>

      <Section className="cs-height_25 cs-height_lg_25" />
      <Section className="cs-member_info">
        <Section tag="h2" className="cs-font_24 cs-font_16_sm cs-m0">
          {firstTeamName}
        </Section>
        <Section className="cs-height_5 cs-height_lg_5" />
        <Section tag="p" className="cs-m0">
          VS
        </Section>
        <Section tag="h2" className="cs-font_24 cs-font_16_sm cs-m0">
          {secondTeamName}
        </Section>
        <Section className="cs-height_20 cs-height_lg_20" />
        <Section className="cs-member_social cs-center">
          {facebookHref && (
            <Link
              to={facebookHref}
              className="cs-primary_color cs-accent_color_hover"
            >
              <Icon icon="fa-regular:futbol" />
            </Link>
          )}
        </Section>
      </Section>
    </Section>
  );
}
