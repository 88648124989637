import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Accordion from "./components/Accordion";
import Section from "./components/Section";
import SectionHeading from "./components/SectionHeading";
import Spacing from "./components/Spacing";
import { info } from "./info";

function Contact() {
  const [contactpagecontent, setcontactpagecontent] = useState(null);
  const [accordionData1, setaccordionData1] = useState(null);
  const [mesajData, setmesajData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const postMessage = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${info.backend_url}items/contact_forms`,
        mesajData
      );
      console.log(res);
      if (res.status == 204 || 200 || 201 || 202 || 203) {
        toast.success("Your message sent. We will reply as soon as possible");
        setmesajData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        toast.error("There is an unexpected Error");
      }
    } catch (error) {
      toast.error("There is an unexpected Error");
    }
  };

  const handleChange = (e) => {
    setmesajData((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const get = async () => {
      const items = await axios.get(info.backend_url + "items/contact_page");
      setcontactpagecontent(items.data.data);
      console.log(items.data.data);
      const faqs = await axios.get(info.backend_url + "items/faq_items");
      setaccordionData1(faqs.data.data);
    };

    get();
  }, []);

  return (
    <>
      <Section tag="section" id="faq">
        <Spacing lg="95" md="70" />
        <Section className="container">
          <SectionHeading
            title={contactpagecontent && contactpagecontent.faq_big_title}
            subtitle={contactpagecontent && contactpagecontent.faq_small_title}
          />
          <Spacing lg="50" md="30" />
          <Section className="row">
            <Section className="col-lg-12">
              {accordionData1 && (
                <Accordion
                  variant=""
                  data={accordionData1}
                  openPosition={0}
                  questionNumberStarter={1}
                />
              )}
            </Section>
          </Section>
        </Section>
      </Section>
      <Section id="contact">
        <Spacing lg="95" md="70" />
        <Section className="container">
          <SectionHeading
            title={contactpagecontent && contactpagecontent.contact_big_title}
            subtitle={
              contactpagecontent && contactpagecontent.contact_small_title
            }
          />
          <Spacing lg="50" md="30" />
          <Section className="row">
            <Section className="col-lg-8 offset-lg-2">
              <form>
                <Section className="row">
                  <Section className="col-lg-6">
                    <Section className="cs-form_field_wrap">
                      <label>Your Name</label>
                      <input
                        type="text"
                        onChange={handleChange}
                        value={mesajData.name}
                        name="name"
                        className="cs-form_field"
                      />
                    </Section>
                    <Spacing lg="30" md="30" />
                  </Section>
                  <Section className="col-lg-6">
                    <Section className="cs-form_field_wrap">
                      <label>Your Email</label>
                      <input
                        type="text"
                        name="email"
                        onChange={handleChange}
                        value={mesajData.email}
                        className="cs-form_field"
                      />
                    </Section>
                    <Spacing lg="30" md="30" />
                  </Section>

                  <Section className="col-lg-12">
                    <Section className="cs-form_field_wrap">
                      <label>Message</label>
                      <textarea
                        className="cs-form_field"
                        cols="30"
                        rows="5"
                        name="message"
                        onChange={handleChange}
                        value={mesajData.message}
                      ></textarea>
                    </Section>
                    <Spacing lg="25" md="25" />
                  </Section>
                  <Section className="col-lg-12">
                    <button
                      className="cs-btn cs-btn_filed cs-accent_btn"
                      onClick={postMessage}
                    >
                      Submit Now
                    </button>
                  </Section>
                </Section>
              </form>
            </Section>
          </Section>
        </Section>
      </Section>
    </>
  );
}

export default Contact;
