import axios from "axios";
import React, { useEffect, useState } from "react";
import { info } from "./info";

import Section from "./components/Section";
import SectionHeading from "./components/SectionHeading";
import Spacing from "./components/Spacing";
import Team from "./components/Team";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

function ArchivedBets() {
  const [page, setpage] = useState(1);
  const limit = 4;

  const [items, setItems] = useState(null);
  const [archivedBets, setArchivedBets] = useState(null);
  const [pageItems, setpageItems] = useState(null);
  const [count, setcount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    const get = async () => {
      const items2 = await axios.get(info.backend_url + "items/bets_page");
      setpageItems(items2.data.data);
    };

    get();
  }, []);
  useEffect(() => {
    const get = async () => {
      const initialUrl = `${info.backend_url}items/bets?filter[status][_eq]=2`;
      const url = initialUrl + `&page=${page}&limit=${limit}`;
      const countUrl = initialUrl + "&aggregate[count]=*";
      //draft 0 published 1 archived 2
      const items = await axios.get(url);
      setItems(items.data.data);
      const resp = await axios.get(countUrl);
      setcount(Math.ceil(resp.data.data[0].count / limit));
    };
    get();
  }, [page]);

  const handlePageClick = (event) => {
    setpage(event.selected + 1);
  };

  return (
    <>
      <Spacing lg="70" md="45" />

      <Section id="team">
        <Section className="container">
          <SectionHeading
            title={pageItems && pageItems.archived_bets_big_title}
            subtitle={pageItems && pageItems.archived_bets_small_title}
          />
          <Spacing lg="50" md="30" />
          <Section className="row">
            {items && items.length > 0 ? (
              items.map((e) => (
                <Section className="col-lg-4 col-sm-6" key={e.id}>
                  <Link to={`/bet/${e.id}`}>
                    <Team
                      firstTeamImage={
                        info.backend_url + "assets/" + e.firstteamlogo
                      }
                      secondTeamImage={
                        info.backend_url + "assets/" + e.secondteamlogo
                      }
                      firstTeamName={e.first_team}
                      secondTeamName={e.second_team}
                      facebookHref={`/bet/${e.id}`}
                    />
                    <Spacing lg="25" md="25" />
                  </Link>
                </Section>
              ))
            ) : (
              <div className="row">
                <div className="col-12">
                  <h3 className="text-center">No result</h3>
                </div>
              </div>
            )}
          </Section>
        </Section>
        <Spacing lg="50" md="30" />
        <div className="row">
          <div
            className="col-md-12"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {count &&
              count > 1 &&
              Array.isArray(items) &&
              items.length !== 0 && (
                <ReactPaginate
                  nextLabel={">"}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  pageCount={count}
                  previousLabel={"<"}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel={"..."}
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              )}
          </div>
        </div>
      </Section>
    </>
  );
}

export default ArchivedBets;
