import React from "react";
import "external-svg-loader";

function Icon({ url, fill, size }) {
  return (
    <>
      <svg
        data-src={url}
        fill={fill}
        width={`${size}px`}
        height={`${size}px`}
      ></svg>
    </>
  );
}

export default Icon;
