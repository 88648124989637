import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ErrorPage from "./components/ErrorPage";
import Footer from "./components/Footer";
import Header from "./components/Header";
import BlogDetails from "./components/Pages/BlogDetails";
import Home from "./components/Pages/Home";
import Spacing from "./components/Spacing";
import Contact from "./Contact";
import "react-toastify/dist/ReactToastify.css";
import { WagmiProvider, createConfig, http } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { bscTestnet, sepolia } from "wagmi/chains";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import Bets from "./Bets";
import Profile from "./Profile";
import ArchivedBets from "./ArchivedBets";
import AddMatch from "./AddMatch";
import "bootstrap/dist/css/bootstrap.min.css";

export const config = createConfig({
  chains: [bscTestnet],
  transports: {
    [bscTestnet.id]: http(),
  },
});
function App() {
  const conf = createConfig(
    getDefaultConfig({
      // Required API Keys
      alchemyId: "BwE39efXkhwLUi3Zla2UthiCF38_upT3", // or infuraId
      walletConnectProjectId: "734adb9155ad6e52e3c09a08880f1bb7",

      // Required
      appName: "Your App Name",

      // Optional
      chains: [bscTestnet],
      transports: {
        [bscTestnet.id]: http(),
      },
    })
  );

  const queryClient = new QueryClient();

  const Web3Provider = ({ children }) => {
    return (
      <WagmiProvider config={conf}>
        <QueryClientProvider client={queryClient}>
          <ConnectKitProvider>{children}</ConnectKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    );
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ fontSize: "14px", zIndex: "10000" }}
      />
      <Web3Provider>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            justifyContent: "space-between",
          }}
        >
          <Header />
          <div>
            <Spacing lg="80" md="80" />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/bets" element={<Bets />} />
              <Route path="/bets-archived" element={<ArchivedBets />} />
              <Route path="/bet/:id" element={<BlogDetails />} />
              <Route path="/add-match" element={<AddMatch />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Web3Provider>
    </>
  );
}

export default App;
