import React from "react";
import { Parallax } from "react-parallax";
import parse from "html-react-parser";
import "./hero.scss";
import Section from "../Section";
import { Link } from "react-router-dom";

export default function Hero({
  title,

  bgUrl,
  heroImageUrl,
  animatedUrl,
  variant,
  overlay,
  bgOpacity,
  buttonName,
  buttonLink,
}) {
  return (
    <Section
      tag="section"
      className={`cs-hero cs-style1 ${variant ? variant : ""}`}
      id="hero"
    >
      {overlay && (
        <Section className="cs-dark_overlay" style={{ opacity: overlay }} />
      )}
      <Section className="cs-hero_bg" style={{ opacity: bgOpacity }}>
        <Parallax bgImage={bgUrl} bgImageAlt="Hero" strength={120}></Parallax>
      </Section>
      <Section className="container">
        <Section className="cs-hero_text">
          <Section
            tag="h1"
            className="cs-hero_title cs-font_64 cs-font_36_sm cs-bold"
          >
            {parse(title)}
          </Section>
          <Section className="cs-btn_group">
            <Section
              tag="span"
              className="cs-btn cs-btn_filed cs-accent_btn"
              onClick={console.log}
            >
              <Section tag="span">
                <Link to={buttonLink}>{buttonName}</Link>
              </Section>
            </Section>{" "}
          </Section>
        </Section>
        <Section className="cs-hero_img">
          <img src={heroImageUrl} alt="Hero" />
          <Section className="cs-hero_img_sm">
            <img src={animatedUrl} alt="Hero" />
          </Section>
        </Section>
      </Section>
    </Section>
  );
}
