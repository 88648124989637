import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Section from "../Section";
import Spacing from "../Spacing";
import { info } from "../../info";
import Countdown from "react-countdown";
import { useReadContract, useWriteContract } from "wagmi";
import { useAccount } from "wagmi";
import { abi } from "../../hooks/abi";
import {
  addDecimal,
  getAllowance,
  getTokenBalance,
  removeDecimal,
} from "../../hooks/wagmiHooks";
import { ConnectKitButton } from "connectkit";
import { Modal, Form, InputGroup, Alert } from "react-bootstrap";
import { erc20Abi } from "viem";
import { readContract } from "@wagmi/core";
import { config } from "../../App";

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return "";
  } else {
    // Render a countdown
    return (
      <span>
        <h3 style={{ color: "green" }}>Match Starts in</h3>
        <h3>
          {days} D : {hours} H : {minutes} M : {seconds} S
        </h3>
      </span>
    );
  }
};

export default function BlogDetails() {
  const { address, isConnected } = useAccount();
  const {
    writeContract: bet,
    error: betError,
    data: betSendData,
  } = useWriteContract();
  const {
    writeContract: approve,
    error: approveError,
    data: approveData,
  } = useWriteContract();
  const {
    writeContract: claim,
    error: claimError,
    data: claimData,
  } = useWriteContract();

  let { id } = useParams();
  const [betData, setBetData] = useState(null);
  const [matchStatus, setMatchStatus] = useState(null);
  const [countDownSeconds, setcountDownSeconds] = useState(null);
  const [showBet, setShowBet] = useState(false);
  const [showClaim, setShowClaim] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");
  const [amountToBet, setAmountToBet] = useState(0);
  const [balance, setBalance] = useState(null);
  const [allowance, setAllowance] = useState(null);
  const [balanceOk, setbalanceOk] = useState(null);
  const [allowanceOk, setallowanceOk] = useState(null);
  const [rate, setrate] = useState(null);
  const [claimed, setclaimed] = useState(0);
  const [claimable, setclaimable] = useState(0);

  useEffect(() => {
    console.log(claimError, approveError, betError);
  }, [claimError, approveError, betError]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleCloseBet = () => setShowBet(false);
  const handleShowBet = () => setShowBet(true);

  const handleCloseClaim = () => setShowClaim(false);
  const handleShowClaim = () => setShowClaim(true);

  const { data: pool } = useReadContract({
    abi,
    address: info.contract_address,
    functionName: "getBetPool",
    args: [id],
    watch: true,
  });

  const { data: usersBet } = useReadContract({
    abi,
    address: info.contract_address,
    functionName: "getUserBetAmounts",
    args: [id, address && address],
    watch: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: isUserClaimed } = useReadContract({
    abi,
    address: info.contract_address,
    functionName: "isUserClaimed",
    args: [Number(id), address],
    watch: true,
  });

  const { data: betInfo } = useReadContract({
    abi,
    address: info.contract_address,
    functionName: "getBetInfo",
    args: [id],
    watch: true,
  });

  useEffect(() => {
    const getBetData = async () => {
      const data = await axios.get(info.backend_url + "items/bets/" + id);
      setBetData(data.data.data);
      console.log(betInfo);
      const matchUnixTime =
        new Date(data.data.data.match_time).getTime() / 1000;
      const nowUnix = Math.floor(Date.now() / 1000);
      setcountDownSeconds(new Date(data.data.data.match_time).getTime());
      if (betInfo) {
        if (betInfo[4] == false) {
          console.log("bet is not open yet");
          setMatchStatus("betnotstarted");
        } else if (betInfo[4] == true && betInfo[0] == true) {
          console.log("match is open for betting");
          setMatchStatus("betsopen");
        } else if (
          betInfo[4] == true &&
          betInfo[0] == false &&
          matchUnixTime - nowUnix <= 0
        ) {
          console.log(
            "bets closed, contract will take match info and release rewards soon"
          );
          setMatchStatus("betisnotfinalized");
        } else if (
          betInfo[4] == true &&
          betInfo[0] == false &&
          betInfo[1] == true
        ) {
          console.log("bets closed, you can check rewards");
          setMatchStatus("betsclaimable");
        }
      }
    };
    getBetData();
  }, [betInfo]);

  useEffect(() => {
    if (pool && matchStatus == "betsclaimable" && betInfo) {
      const rat =
        (Number(pool[1].toString()) +
          Number(pool[0].toString()) +
          Number(pool[2].toString())) /
        Number(pool[betInfo[2]].toString());
      setrate(rat);
    }
  }, [pool, betInfo, matchStatus]);

  useEffect(() => {
    if ((rate, address, isConnected, usersBet)) {
      const claim =
        rate * Number(removeDecimal(usersBet[betInfo[2]].toString(), 18));
      setclaimable(claim.toFixed(2));
      console.log("claimable", claim);
    }
  }, [rate, address, isConnected, usersBet]);

  const { data: allowan } = useReadContract({
    abi: erc20Abi,
    address: info.token_address,
    functionName: "allowance",
    args: [address && address, info.contract_address],
    watch: true,
  });

  useEffect(() => {
    const getInfo = async () => {
      if (address && isConnected) {
        const bal = await getTokenBalance(address);
        setBalance(bal);
      }
    };
    getInfo();
  }, [address, isConnected]);

  useEffect(() => {
    if (address && isConnected) {
      console.log(allowan);

      if (balance - amountToBet >= 0) {
        setbalanceOk(true);
      } else {
        setbalanceOk(false);
      }
      if (removeDecimal(allowan?.toString(), 18) - amountToBet >= 0) {
        setallowanceOk(true);
      } else {
        setallowanceOk(false);
      }
    }
  }, [amountToBet, allowan, balance, address]);

  return (
    <>
      <Spacing lg="95" md="65" />
      <Section className="container">
        <Section className="row">
          <Section className="col-lg-8 offset-lg-2">
            <Section className="cs-blog_details">
              <Spacing lg="10" md="10" />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <div className="text-center">
                  <img
                    src={
                      betData &&
                      info.backend_url + "assets/" + betData.firstteamlogo
                    }
                    width="300"
                    height="300"
                    alt=""
                  />
                  <Section tag="h3" className="mt-4">
                    {betData && betData.first_team}
                  </Section>
                </div>

                <div className="text-center">
                  <img
                    src={
                      betData &&
                      info.backend_url + "assets/" + betData.secondteamlogo
                    }
                    width="300"
                    height="300"
                    alt=""
                  />
                  <Section tag="h3" className="mt-4">
                    {betData && betData.second_team}
                  </Section>
                </div>
              </div>
              <div className="text-center">
                {countDownSeconds && (
                  <Countdown date={countDownSeconds} renderer={renderer} />
                )}
              </div>
            </Section>
            {betData && matchStatus != "betnotstarted" && (
              <div
                style={{
                  width: "100%",
                  border: "1px solid gray",
                  borderRadius: "8px",
                  padding: "15px",
                }}
              >
                <div className="row mt-3 mb-3">
                  <div className="col-12 text-center">
                    <h4>Bet Info</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 text-center">
                    <img
                      src={
                        betData &&
                        info.backend_url + "assets/" + betData.firstteamlogo
                      }
                      width="60"
                      height="60"
                      alt=""
                    />
                  </div>
                  <div className="col-4 text-center"></div>
                  <div className="col-4 text-center">
                    <img
                      src={
                        betData &&
                        info.backend_url + "assets/" + betData.secondteamlogo
                      }
                      width="60"
                      height="60"
                      alt=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 text-center">
                    <h5 className="mt-2">{betData.first_team}</h5>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mt-2">Draw</h5>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mt-2">{betData.second_team}</h5>
                  </div>
                </div>
                {!address && !isConnected && (
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <h4>Please connect your wallet to see your bets</h4>
                    </div>
                  </div>
                )}
                {address && isConnected && (
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <h4>Your Bets</h4>
                    </div>
                    {usersBet && (
                      <>
                        <div className="col-4 text-center">
                          <h5 className="mt-2">
                            {removeDecimal(usersBet[1].toString(), 18, 2) + " "}
                            USDT
                          </h5>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mt-2">
                            {removeDecimal(usersBet[0].toString(), 18, 2) + " "}
                            USDT
                          </h5>
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="mt-2">
                            {removeDecimal(usersBet[2].toString(), 18, 2) + " "}
                            USDT
                          </h5>
                        </div>
                      </>
                    )}
                  </div>
                )}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <h4>Bet Pool</h4>
                  </div>
                  {pool && (
                    <>
                      <div className="col-4 text-center">
                        <h5 className="mt-2">
                          {removeDecimal(pool[1].toString(), 18, 2) + " "}
                          USDT
                        </h5>
                      </div>
                      <div className="col-4 text-center">
                        <h5 className="mt-2">
                          {removeDecimal(pool[0].toString(), 18, 2) + " "}
                          USDT
                        </h5>
                      </div>
                      <div className="col-4 text-center">
                        <h5 className="mt-2">
                          {removeDecimal(pool[2].toString(), 18, 2) + " "}
                          USDT
                        </h5>
                      </div>
                      {matchStatus == "betisnotfinalized" && (
                        <Alert variant="warning">
                          Contract is processing match result, claim will be
                          available soon.
                        </Alert>
                      )}
                      {matchStatus == "betsopen" && (
                        <div className="col-12 text-center mt-3">
                          {address && isConnected && (
                            <button
                              className="btn btn-primary mt-3"
                              onClick={handleShowBet}
                            >
                              {address ? "Bet" : "Connect Wallet"}
                            </button>
                          )}
                          {!address && !isConnected && (
                            <ConnectKitButton.Custom>
                              {({ show, hide, ensName, chain }) => {
                                return (
                                  <button
                                    className="btn btn-success mt-5"
                                    onClick={show}
                                  >
                                    {address ? "" : "Connect Wallet"}
                                  </button>
                                );
                              }}
                            </ConnectKitButton.Custom>
                          )}
                        </div>
                      )}
                      {matchStatus == "betsclaimable" && (
                        <div className="col-12 text-center mt-3">
                          {address && isConnected && (
                            <button
                              className="btn btn-primary mt-3"
                              onClick={handleShowClaim}
                            >
                              {address ? "Check Rewards" : "Connect Wallet"}
                            </button>
                          )}
                          {!address && !isConnected && (
                            <ConnectKitButton.Custom>
                              {({ show, hide, ensName, chain }) => {
                                return (
                                  <button
                                    className="btn btn-success mt-5"
                                    onClick={show}
                                  >
                                    {address ? "" : "Connect Wallet"}
                                  </button>
                                );
                              }}
                            </ConnectKitButton.Custom>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {betData && matchStatus == "betnotstarted" && (
              <Alert variant="warning">
                Bet is not open yet, soon you will be available to bet on this
                match.
              </Alert>
            )}
          </Section>
          {betData && matchStatus == "betsopen" && (
            <Modal show={showBet} onHide={handleCloseBet} data-bs-theme="dark">
              <Modal.Header closeButton>
                <Modal.Title>Place Your Bet</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-4 text-center">
                    <img
                      src={
                        betData &&
                        info.backend_url + "assets/" + betData.firstteamlogo
                      }
                      width="60"
                      height="60"
                      alt=""
                    />
                  </div>
                  <div className="col-4 text-center"></div>
                  <div className="col-4 text-center">
                    <img
                      src={
                        betData &&
                        info.backend_url + "assets/" + betData.secondteamlogo
                      }
                      width="60"
                      height="60"
                      alt=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 text-center">
                    <h5 className="mt-2">{betData.first_team}</h5>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mt-2">Draw</h5>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mt-2">{betData.second_team}</h5>
                  </div>
                  <Form>
                    <div className="row text-center">
                      <div className="col-4">
                        <Form.Check
                          type="radio"
                          id="option1"
                          name="options"
                          value="1"
                          checked={selectedOption === "1"}
                          onChange={handleOptionChange}
                        />
                      </div>
                      <div className="col-4">
                        <Form.Check
                          type="radio"
                          id="option2"
                          name="options"
                          value="0"
                          checked={selectedOption === "0"}
                          onChange={handleOptionChange}
                        />
                      </div>
                      <div className="col-4">
                        <Form.Check
                          type="radio"
                          id="option3"
                          name="options"
                          value="2"
                          checked={selectedOption === "2"}
                          onChange={handleOptionChange}
                        />
                      </div>
                      <div className="col-12 mt-3 text-center">
                        <h4>Amount</h4>
                        <InputGroup>
                          <Form.Control
                            value={amountToBet}
                            onChange={(e) => setAmountToBet(e.target.value)}
                            type="number"
                          />
                          <InputGroup.Text id="basic-addon2">
                            USDT
                          </InputGroup.Text>
                        </InputGroup>
                        {!allowanceOk && (
                          <Alert variant="warning" className="mt-3">
                            You need to approve USDT first
                          </Alert>
                        )}
                        {!balanceOk && (
                          <Alert variant="danger" className="mt-3">
                            You don't have enough amount of USDT
                          </Alert>
                        )}
                        {allowanceOk && (
                          <button
                            className="btn btn-primary mt-3"
                            disabled={!balanceOk}
                            onClick={(e) => {
                              e.preventDefault();
                              try {
                                console.log(
                                  id,
                                  selectedOption,
                                  addDecimal(amountToBet, 18, 0)
                                );
                                bet({
                                  abi: abi,
                                  address: info.contract_address,
                                  functionName: "bet",
                                  args: [
                                    id,
                                    selectedOption,
                                    addDecimal(amountToBet, 18, 0),
                                  ],
                                });
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          >
                            Bet
                          </button>
                        )}
                        {!allowanceOk && (
                          <button
                            className="btn btn-primary mt-3"
                            onClick={(e) => {
                              e.preventDefault();
                              approve({
                                abi: erc20Abi,
                                address: info.token_address,
                                functionName: "approve",
                                args: [
                                  info.contract_address,
                                  addDecimal(amountToBet, 18),
                                ],
                              });
                            }}
                          >
                            Approve
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </Modal.Body>
            </Modal>
          )}
          {betData && matchStatus == "betsclaimable" && usersBet && (
            <Modal
              show={showClaim}
              onHide={handleCloseClaim}
              data-bs-theme="dark"
            >
              <Modal.Header closeButton>
                <Modal.Title>Claim Your Reward</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-12 text-center">
                    <h5>Winner</h5>
                  </div>
                  <div className="col-4 text-center">
                    <img
                      src={
                        betData &&
                        info.backend_url + "assets/" + betData.firstteamlogo
                      }
                      width="60"
                      height="60"
                      alt=""
                    />
                  </div>
                  <div className="col-4 text-center"></div>
                  <div className="col-4 text-center">
                    <img
                      src={
                        betData &&
                        info.backend_url + "assets/" + betData.secondteamlogo
                      }
                      width="60"
                      height="60"
                      alt=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 text-center">
                    <h5 className="mt-2">{betData.first_team}</h5>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mt-2">Draw</h5>
                  </div>
                  <div className="col-4 text-center">
                    <h5 className="mt-2">{betData.second_team}</h5>
                  </div>
                  <Form>
                    <div className="row text-center">
                      <div className="col-4">
                        <Form.Check
                          type="radio"
                          id="option1"
                          name="options"
                          value="1"
                          disabled={true}
                          checked={betInfo[2].toString() === "1"}
                          onChange={handleOptionChange}
                        />
                      </div>
                      <div className="col-4">
                        <Form.Check
                          type="radio"
                          id="option2"
                          name="options"
                          value="0"
                          disabled={true}
                          checked={betInfo[2].toString() === "0"}
                          onChange={handleOptionChange}
                        />
                      </div>
                      <div className="col-4">
                        <Form.Check
                          type="radio"
                          id="option3"
                          name="options"
                          value="2"
                          disabled={true}
                          checked={betInfo[2].toString() === "2"}
                          onChange={handleOptionChange}
                        />
                      </div>
                      <div className="col-12 mt-3 text-center">
                        <h4>Amount</h4>
                        <div className="col-6 text-center">
                          <h5>
                            Your Reward : {claimable && claimable}
                            {" USDT"}
                          </h5>
                        </div>

                        {isUserClaimed ? (
                          <Alert variant="success">
                            You already claimed your reward.
                          </Alert>
                        ) : (
                          <button
                            className="btn btn-primary mt-3"
                            disabled={!balanceOk}
                            onClick={(e) => {
                              e.preventDefault();
                              try {
                                console.log(
                                  id,
                                  selectedOption,
                                  addDecimal(amountToBet, 18, 0)
                                );
                                claim({
                                  abi: abi,
                                  address: info.contract_address,
                                  functionName: "claimReward",
                                  args: [id],
                                });
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          >
                            Claim
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </Section>
      </Section>
      <Spacing lg="40" md="20" />
    </>
  );
}
