import React, { useEffect, useState } from "react";
import { useAccount, useReadContract } from "wagmi";
import { useSearchParams } from "react-router-dom";
import { config } from "./App";
import { info } from "./info";
import { abi } from "./hooks/abi";
import { readContract } from "@wagmi/core";
import { ConnectKitButton } from "connectkit";
import Alert from "react-bootstrap/Alert";
import { useWriteContract } from "wagmi";

function AddMatch() {
  const { address, isConnected } = useAccount();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [date, setDate] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { writeContract } = useWriteContract();

  useEffect(() => {
    const id1 = searchParams.get("id");
    const date1 = searchParams.get("date");
    if (id1) {
      setId(id1);
    }
    if (date1) {
      setDate(date1);
    }
  }, []);

  const { data: result } = useReadContract({
    abi: abi,
    address: info.contract_address,
    functionName: "getBetInfo",
    args: [id],
    watch: true,
  });

  const getInfo = async () => {
    if (result) {
      if (result[4]) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getInfo();
    }
  }, [id, result]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 mt-5">
          <label htmlFor="">ID</label>
          <input
            type="number"
            value={id}
            name="id"
            onChange={(e) => setId(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-6 mt-5">
          <label htmlFor="">DATE</label>
          <input
            type="text"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="form-control"
          />
        </div>
        {buttonDisabled && (
          <Alert variant="danger" className="mt-3">
            This match already defined on the contract
          </Alert>
        )}
        {address && isConnected && (
          <button
            className="btn btn-success mt-3"
            disabled={buttonDisabled}
            onClick={() => {
              id &&
                date &&
                writeContract({
                  abi,
                  address: info.contract_address,
                  functionName: "createBet",
                  args: [new Date(date).getTime() / 1000, id],
                });
            }}
          >
            {address ? "Add Match" : "Connect Wallet"}
          </button>
        )}
        {!address && !isConnected && (
          <ConnectKitButton.Custom>
            {({ show, hide, ensName, chain }) => {
              return (
                <button className="btn btn-success mt-5" onClick={show}>
                  {address ? "" : "Connect"}
                </button>
              );
            }}
          </ConnectKitButton.Custom>
        )}
      </div>
    </div>
  );
}

export default AddMatch;
