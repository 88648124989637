import axios from "axios";
import React, { useEffect, useState } from "react";
import { info } from "./info";

import Section from "./components/Section";
import SectionHeading from "./components/SectionHeading";
import Spacing from "./components/Spacing";
import Team from "./components/Team";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

function Bets() {
  const [items, setItems] = useState(null);
  const [pageItems, setpageItems] = useState(null);
  const [dates, setdates] = useState(null);
  const [leagues, setleagues] = useState(null);
  const [chosenLeague, setChosenLeague] = useState(null);
  const [chosenDate, setChosenDate] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const get = async () => {
      const items2 = await axios.get(info.backend_url + "items/bets_page");
      setpageItems(items2.data.data);
    };

    get();
  }, []);
  useEffect(() => {
    const get = async () => {
      var initialUrl;

      if (chosenLeague) {
        initialUrl = `${info.backend_url}items/bets?filter[status][_eq]=1&filter[bet_league][_eq]=${chosenLeague}`;
      } else {
        initialUrl = `${info.backend_url}items/bets?filter[status][_eq]=1`;
      }
      //draft 0 published 1 archived 2
      const items = await axios.get(initialUrl);
      setItems(items.data.data);
      getDates();
    };
    get();
  }, [chosenLeague]);

  useEffect(() => {
    const get = async () => {
      const items2 = await axios.get(info.backend_url + "items/league");
      setleagues(items2.data.data);
    };

    get();
  }, []);

  function convertDate(dateTimeString) {
    return dateTimeString.split("T")[0];
  }

  function getDates() {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }

    const today = new Date();
    const tomorrow = new Date(today);
    const dayAfterTomorrow = new Date(today);

    tomorrow.setDate(today.getDate() + 1);
    dayAfterTomorrow.setDate(today.getDate() + 2);

    const result = {
      today: {
        dayName: daysOfWeek[today.getDay()],
        date: formatDate(today),
      },
      tomorrow: {
        dayName: daysOfWeek[tomorrow.getDay()],
        date: formatDate(tomorrow),
      },
      dayAfterTomorrow: {
        dayName: daysOfWeek[dayAfterTomorrow.getDay()],
        date: formatDate(dayAfterTomorrow),
      },
    };
    setdates(result);
  }

  return (
    <>
      <Spacing lg="70" md="45" />

      <Section id="team">
        <Section className="container">
          <SectionHeading
            title={pageItems && pageItems.active_bets_big_title}
            subtitle={pageItems && pageItems.active_bets_small_title}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
          />
          <Spacing lg="50" md="30" />
          <Section className="row">
            {showFilters && (
              <>
                <div className="col-md-9 offset-md-3 text-center">
                  {dates && (
                    <div className="row p-2">
                      <div className="col-md-3 mt-3 mb-3 p-2">
                        <div
                          className="cs-iconbox cs-style1 cs-type1 text-center cs-light_bg league-card"
                          style={{
                            backgroundColor:
                              chosenDate == dates.today.date && "#191818",
                          }}
                          onClick={() => {
                            setChosenDate(dates.today.date);
                          }}
                        >
                          <h5 style={{ margin: 0, lineHeight: 1 }}>Today</h5>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3 mb-3 p-2">
                        {" "}
                        <div
                          className="cs-iconbox cs-style1 cs-type1 text-center cs-light_bg league-card"
                          style={{
                            backgroundColor:
                              chosenDate == dates.tomorrow.date && "#191818",
                          }}
                          onClick={() => {
                            setChosenDate(dates.tomorrow.date);
                          }}
                        >
                          <h5 style={{ margin: 0, lineHeight: 1 }}>
                            {dates.tomorrow.dayName}
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3 mb-3 p-2">
                        <div
                          className="cs-iconbox cs-style1 cs-type1 text-center cs-light_bg league-card"
                          onClick={() => {
                            setChosenDate(dates.dayAfterTomorrow.date);
                          }}
                          style={{
                            backgroundColor:
                              chosenDate == dates.dayAfterTomorrow.date &&
                              "#191818",
                          }}
                        >
                          <h5 style={{ margin: 0, lineHeight: 1 }}>
                            {dates.dayAfterTomorrow.dayName}
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3 mb-3 p-2">
                        <div
                          className="cs-iconbox cs-style1 cs-type1 text-center cs-light_bg league-card"
                          onClick={() => {
                            setChosenDate(null);
                          }}
                          style={{
                            backgroundColor: !chosenDate && "#191818",
                          }}
                        >
                          <h5 style={{ margin: 0, lineHeight: 1 }}>All</h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-3 mt-3 mb-3">
                  {leagues &&
                    leagues.map((e) => (
                      <div
                        style={{
                          backgroundColor: chosenLeague == e.id && "#191818",
                        }}
                        className="cs-iconbox cs-style1 cs-type1 text-center cs-light_bg league-card"
                        onClick={() => setChosenLeague(e.id)}
                      >
                        <h5 style={{ margin: 0, lineHeight: 1 }}>
                          {e.league_name}
                        </h5>
                        <img
                          src={info.backend_url + "assets/" + e.league_image}
                          alt=""
                          width="40"
                        />
                      </div>
                    ))}
                  <div
                    style={{
                      backgroundColor: !chosenLeague && "#191818",
                    }}
                    className="cs-iconbox cs-style1 cs-type1 text-center cs-light_bg league-card"
                    onClick={() => setChosenLeague(null)}
                  >
                    <h5 style={{ margin: 0, lineHeight: 1 }}>All Leagues</h5>
                  </div>
                </div>
              </>
            )}

            <div className={showFilters ? "col-md-9" : "col-md-12"}>
              <div className="row">
                {items && items.length > 0 ? (
                  items.map((e) => (
                    <>
                      {chosenDate ? (
                        <>
                          {chosenDate == convertDate(e.match_time) && (
                            <Section className="col-lg-4 col-sm-6" key={e.id}>
                              <Link to={`/bet/${e.id}`}>
                                <Team
                                  firstTeamImage={
                                    info.backend_url +
                                    "assets/" +
                                    e.firstteamlogo
                                  }
                                  secondTeamImage={
                                    info.backend_url +
                                    "assets/" +
                                    e.secondteamlogo
                                  }
                                  firstTeamName={e.first_team}
                                  secondTeamName={e.second_team}
                                  facebookHref={`/bet/${e.id}`}
                                />
                                <Spacing lg="25" md="25" />
                              </Link>
                            </Section>
                          )}
                        </>
                      ) : (
                        <Section className="col-lg-4 col-sm-6" key={e.id}>
                          <Link to={`/bet/${e.id}`}>
                            <Team
                              firstTeamImage={
                                info.backend_url + "assets/" + e.firstteamlogo
                              }
                              secondTeamImage={
                                info.backend_url + "assets/" + e.secondteamlogo
                              }
                              firstTeamName={e.first_team}
                              secondTeamName={e.second_team}
                              facebookHref={`/bet/${e.id}`}
                            />
                            <Spacing lg="25" md="25" />
                          </Link>
                        </Section>
                      )}
                    </>
                  ))
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <h3 className="text-center">No result</h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Section>
        </Section>
        <Spacing lg="50" md="30" />
      </Section>
    </>
  );
}

export default Bets;
