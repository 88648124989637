import Icon from "../Icon";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Section from "../Section";
import "./footer.scss";
import { info } from "../../info";
import axios from "axios";

export default function Footer() {
  const [siteInfo, setSiteInfo] = useState(null);
  const [socials, setsocials] = useState(null);

  useEffect(() => {
    const get = async () => {
      const items = await axios.get(
        info.backend_url + "items/website_settings"
      );
      setSiteInfo(items.data.data);
      const socialitems = await axios.get(
        info.backend_url + "items/social_accounts"
      );
      setsocials(socialitems.data.data);
    };
    get();
  }, []);
  return (
    <Section
      tag="footer"
      className="cs-footer_wrap cs-bg"
      style={{ backgroundImage: "url(/images/footer_bg_1.png)" }}
    >
      <Section tag="footer" className="cs-footer text-center">
        <Section className="container">
          <Section className="cs-height_100 cs-height_lg_70" />
          <Section className="cs-footer_logo">
            <img
              src={siteInfo && info.backend_url + "assets/" + siteInfo.logo}
              alt="logo"
              className="cs-hide_dark"
            />
            <img
              src={siteInfo && info.backend_url + "assets/" + siteInfo.logo}
              alt="logo"
              className="cs-hide_white"
            />
          </Section>
          <Section className="cs-height_25 cs-height_lg_25" />
          <Section className="cs-social_btns cs-center">
            {socials &&
              socials.map((e) => (
                <a
                  target="_blank"
                  href={e.link}
                  className="cs-center cs-primary_color cs-accent_bg_hover cs-light_bg cs-white_color_hover"
                  key={e.link}
                >
                  <Icon
                    url={info.backend_url + "assets/" + e.icon}
                    fill="white"
                    size="32"
                  />
                </a>
              ))}
          </Section>
        </Section>
        <Section className="cs-height_45 cs-height_lg_25" />
        <Section className="container">
          <Section className="cs-copyright text-center">
            Copyright © 2024. All Rights Reserved by{" "}
            <span className="cs-primary_font cs-primary_color">ZPORTZ365</span>
          </Section>
        </Section>
        <Section className="cs-height_25 cs-height_lg_25" />
      </Section>
    </Section>
  );
}
