import { readContract, getBalance, erc721ABI } from "@wagmi/core";
import BigNumber from "bignumber.js";
import { erc20Abi } from "viem";
import { config } from "../App";
import { info } from "../info";

// Token balance'ına ondalık eklemek için
export function removeDecimal(balance, decimal, fixed) {
  if (decimal > 0) {
    const balanceBig = new BigNumber(String(balance));
    const decimalBig = new BigNumber(String(10)).exponentiatedBy(
      String(decimal)
    );
    const result = new BigNumber(balanceBig.dividedBy(decimalBig));
    return result.toFixed(fixed);
  } else {
    const balanceBig = new BigNumber(String(balance));
    return balanceBig.toFixed(fixed);
  }
}

// Token balance'ından ondalık çıkarmak için
export function addDecimal(balance, decimal, fixed) {
  if (decimal > 0) {
    const balanceBig = new BigNumber(String(balance));
    const decimalBig = new BigNumber(String(10)).exponentiatedBy(
      String(decimal)
    );
    const result = new BigNumber(balanceBig.multipliedBy(decimalBig));
    return result.toFixed(fixed);
  } else {
    const balanceBig = new BigNumber(String(balance));
    return balanceBig.toFixed(fixed);
  }
}

export const getTokenBalance = async (walletAddress) => {
  try {
    const data = await readContract(config, {
      address: info.token_address,
      abi: erc20Abi,
      functionName: "balanceOf",
      args: [walletAddress],
    });
    return Number(removeDecimal(data.toString(), 18));
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchBalance = async (walletAddress) => {
  try {
    const balance = await getBalance({
      address: walletAddress,
    });
    return balance;
  } catch (error) {
    return null;
  }
};

export const getAllowance = async (address) => {
  try {
    const data = await readContract(config, {
      address: info.token_address,
      abi: erc20Abi,
      functionName: "allowance",
      args: [address, info.contract_address],
    });
    return Number(removeDecimal(data.toString(), 18));
  } catch (error) {
    console.log(error);

    return 0;
  }
};
